<template>
  <v-container fluid class="pa-0">
    <!-- <v-card width="100%" height="900" img="../assets/images/banner_bg.png"></v-card> -->
    <v-img src="../assets/images/banner_bg.png" class="d-flex justify-center align-center pt-12">
      <v-img class="mx-auto" max-width="670" src="../assets/images/banner_headline.png"></v-img>
      <v-img class="mx-auto mt-6" max-width="670" src="../assets/images/banner_subheading.png"></v-img>
      <!-- banner_subheading.png -->
    </v-img>
    <v-container fluid class="pa-0 pt-12 pb-12 content" style="background-color:#F9F9FF;">
      <v-card
        color="transparent"
        max-width="1200"
        class="mx-auto pt-12"
        style="background-color:#F9F9FF;"
        tlte
        flat
      >
        <v-btn
          class="headline"
          color="#1A80FF"
          min-height="60"
          min-width="300"
          outlined
          rounded
        >计量检验平台简介</v-btn>
        <p
          class="title text-left mb-0 mt-12 introduction-txt"
        >计量检验平台是一个基于云计算和区块链技术，集计量计重、防伪溯源、报告出证、大数据分析等丰富功能于 一体的信息化服务平台，系统由云端服务平台和手机端作业程序组成。</p>
        <p
          class="title text-left mb-0 introduction-txt"
        >目前，国内利用船舶舱进行货物贸易交接时，对舱内货物量的认定，仍然是以人工计量的结果为准。除了劳动 强度大、工作效率低之外，人工计量结果中总是含有“人工”因素，导致贸易结算各方因计量结果的准确性问题发 生分歧、矛盾甚至纠纷的现象也越来越普遍，造成了许多不必要的社会经济损失。</p>
        <p
          class="title text-left mb-0 introduction-txt"
        >通过本平台的应用降低了计量检验人员的劳动强度并提高了工作效率，为贸易结算各方提供准确、可靠的计量 数据，减少计量误差，避免计量纠纷，并且大大缩短计量报告出具时间，减少船舶滞港期，节约运输成本。</p>
      </v-card>
      <v-card
        color="transparent"
        max-width="1200"
        class="mx-auto pt-12"
        style="background-color:#F9F9FF;"
        tlte
        flat
      >
        <v-card color="transparent" v-for="(item,index) in features" :key="index+ '-only'" flat>
          <v-img
            max-width="200"
            src="../assets/images/characteristic_bg.png"
            class="d-flex align-center"
          >
            <p class="mb-0 features-title">{{item.title}}</p>
          </v-img>
          <p class="text-left title mt-8 features-txt">{{item.content}}</p>
          <v-row v-if="item.details">
            <v-col
              class="text-left"
              md="3"
              sm="6"
              cols="12"
              v-for="(item1,i) in item.details"
              :key="i"
            >
              <p class="headline features-col-title">{{item1.title}}</p>
              <p class="title features-col-txt">{{item1.content}}</p>
            </v-col>
          </v-row>
        </v-card>
        <div id="embodiment"></div>
        <v-btn
          class="headline"
          color="#1A80FF"
          min-height="60"
          min-width="350"
          outlined
          rounded
          style="margin-top:200px"
        >计量检验平台的价值体现</v-btn>
        <v-card
          class="mt-8"
          color="transparent"
          v-for="(item3,index3) in valueEmbodiment"
          :key="index3"
          flat
        >
          <div
            style="width:170px;height:50px;background:rgba(26,128,255,1);border-radius:25px;font-size:22px;line-height:50px;color:#fff"
          >{{item3.title}}</div>
          <p class="text-left title mt-8 features-txt mb-0">{{item3.content}}</p>
        </v-card>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    features: [
      {
        id: 0,
        title: "业务数字化",
        content: "基于云计算和区块链技术，实现全业务流程的数字化。包括:",
        details: [
          {
            title: "数字化操作",
            content:
              "可使用手机微信小程序作为操作终端，也可使用电脑浏览器打开平台WEB页面程序进行操作全面涵盖各种现场作业和后场管理应用场景；"
          },
          {
            title: "数字化报表",
            content:
              "自动生成PDF格式作业报表，支持二维码防伪支持电子签章，可根据实际需要进行打印也可通过微信、QQ等快速传递作业报表；"
          },
          {
            title: "数字化分析",
            content:
              "所有测量数据和计量结 果永久保存在数据库， 系统提供大数据统计分 析算法，通过数据可视 化界面展现分析结果， 支持大屏投放；"
          },
          {
            title: "数字化船舶资料",
            content:
              "平台内置舱容表等计量 计重所需船舶资料，避 免传统纸质船舶资料的 破损、油污、丢失等种 种不便。"
          }
        ]
      },
      {
        title: "流程标准化",
        content:
          "平台系统内置标准化计量计重作业流程，只需遵照程序引导一步步操作即可完成符合规范的计量计重作业，大大降低人员培训成本。"
      },
      {
        title: "操作人性化",
        content:
          "平台具有友好而清新的用户界面，操作快捷、简单易用，支持无线打印，可满足用户在作业现场出具计量报告的需求。"
      },
      {
        title: "防错智能化",
        content:
          "通过人工智能技术对各项作业数据进行校验，结合大数据分析提供参考数据（如经验常数、经验底量等），有效防止因观测数据错误而产生的结果异常。"
      }
    ],
    valueEmbodiment: [
      {
        title: "速度快",
        content:
          "对船舶所装载的货物进行计量计重是一项专业性很强的工作。在传统的人工计算方式下，完成一次计量计重结果的计算工作，至少需要30分钟以上的时间。通过使用计量检验平台，只需要在手机上简单地操作，短短几分钟就能够完成全部计算并输出计量结果报告。大大提高工作效率，缩短船舶滞港时间。"
      },
      {
        title: "结果准",
        content:
          "货物计量计重过程中，影响结果的因素很多，需要大量繁杂的计算，如纵倾横倾修正、拱陷校正、膨胀修正、密度换算等等，不同船舶的计算方式还不尽相同，在人工计算的过程中很容易发生差错。采用计量检验平台以后，这些计算工作都交给计算机，通过系统内置的全套智能算法，工作人员只需要输入吃水、空距等实际观测值即可自动完成计算，无需担心计算差错。"
      },
      {
        title: "精度高",
        content:
          "在计量计重工作中，对于计算中过程数据的取舍精度，往往会造成最终结果的较大偏差，而人工计算时无法保留较高的计算精度，通常只精确到小数点后三位。使用计量检验平台，可根据实际需要设定系统计算精度，最高可精确到小数点后6位，大幅减少高价值货品的精度误差损失。"
      },
      {
        title: "数据全",
        content:
          "计量检验平台系统不仅内置船舶排水量表、舱容表等资料数据，还将每一次装卸作业的计算结果，以及实测的吃水液位、温度等计算依据，都在数据库中永久保存，并且对作业数据提供了照片存证功能。日后需要时可以方便地进行检索查询，避免了传统纸质单据的破损、丢失、查询统计不便等弊端。并通过大数据技术对每条船舶的经验常数运载能力、亏舱率、装卸效率等各方面情况进行统计分析，为行业上下游客户提供参考。"
      },
      {
        title: "传输易",
        content:
          "计量检验平台系统自动生成PDF格式的计量结果报告，既可连接便携式打印机进行打印输出，也可直接在手机上通过QQ、微信等常用APP进行分享传输，并且电子报告还具备电子签名、防伪查验功能。解决了传统纸质报表保管传输不便的困难。"
      },
      {
        title: "功能强",
        content:
          "计量检验平台不仅提供计量计重的作业功能，并且提供远程实时的管理功能。业务管理人员可以通过平台远程实时查看现场作业数据，发现问题及时干预指导，提升过程管理能力，提高运输服务水平；船公司也可将计量平台作为日常管理工具，船员可通过手机终端每日进行油、水、货量的统计上报。"
      },
      {
        title: "防伪造",
        content:
          "计量检验平台将舱容表、排水量表等船舶资料，以及计量报告和作业数据等加密存储在区块链上，利用区块链的去中心化、无法窜改、数据加密等特性，保证数据的安全和准确。"
      },
      {
        title: "降成本",
        content:
          "传统方式培养一个能够完成计量计重作业的合格工作人员，需要大量的培训和实践时间，成本很高，并且人员水平良莠不齐。使用了计量平台以后，通过系统标准化的作业流程和强大的计算能力，只需要对水尺、液位和温度等测量方法进行简单培训即可，新手可以在短时间内具备上岗作业能力，大大降低人员培训的人力物力和时间成本。"
      }
    ]
  })
};
</script>

<style scoped>
.introduction-txt {
  color: #1c3249;
  text-indent: 2em;
  line-height: 48px;
}

.features-title {
  color: #fff;
  font-size: 22px;
  letter-spacing: 5px;
}

.features-txt {
  color: #1c3249;
  line-height: 40px;
  letter-spacing: 5px !important;
}

.features-col-title {
  color: #1a80ff;
  letter-spacing: 5px !important;
}

.features-col-txt {
  max-width: 244px;
  color: #1c3249;
  letter-spacing: 5px !important;
}

.content {
  background: url(../assets/images/ship_bg_one.png) no-repeat;
  background-size: 830px 550px;
  background-position: 100% 400px;
}
</style> 